
import { defineComponent, PropType } from 'vue';
import { LocaleMessage } from 'vue-i18n';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

// Components
import { Backdrop, CavuButton } from 'ui-shared-components';
import NavbarPartnerLink from '../navbar-partner-link/navbar-partner-link.vue';
import NavbarTabs from '../navbar-tabs/navbar-tabs.vue';

export type MobileMenuData = {
  nickname: string;
  logoutHandler: () => void;
  makeNewBookingHandler: () => void;
};

export type TabsData = {
  tabsLinks: {
    bookingLink?: string;
    myProfileLink?: string;
    bookingSearchLink?: string;
  };
};

export default defineComponent({
  name: 'UserMenuMobile',

  components: {
    Backdrop,
    CavuButton,
    IconClose: () => import('@white-label-icon/icon-close'),
    IconHamburger: () => import('@white-label-icon/icon-hamburger'),
    IconPlusCircle: () => import('@white-label-icon/icon-plus-circle'),
    NavbarPartnerLink,
    NavbarTabs,
  },

  props: {
    menuData: {
      type: Object as PropType<MobileMenuData>,
      default: () => ({}),
    },

    tabsData: {
      type: Object as PropType<TabData>,
      default: () => ({}),
    },

    hideDefaultButtons: {
      type: Boolean,
    },

    partnerName: {
      required: true,
      type: String as PropType<string>,
    },
  },

  data() {
    return {
      isOpened: false,
      isBookingPortal,
    };
  },

  computed: {
    hideBookingButtons() {
      return this.$launchDarkly?.variation(
        'PREM-856-Aether-Hide-Booking-Buttons'
      );
    },

    logoutText(): LocaleMessage {
      return this.$t('UI.buttons.logOut');
    },

    makeNewBookingText(): LocaleMessage {
      return this.$t('UI.buttons.makeNewBooking');
    },

    nickname(): unknown {
      return this.menuData?.nickname;
    },
  },

  methods: {
    makeNewBooking() {
      this.hide();
      if (this.menuData) {
        'makeNewBookingHandler' in this.menuData &&
          this.menuData.makeNewBookingHandler();
      }
    },

    logoutHandler() {
      this.hide();
      if (this.menuData) {
        'logoutHandler' in this.menuData && this.menuData.logoutHandler();
      }
    },

    show() {
      this.isOpened = true;
    },

    hide() {
      this.isOpened = false;
    },
  },
});
