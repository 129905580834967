const middleware = {}

middleware['dedicated-auth0'] = require('../src/middleware/dedicated-auth0.ts')
middleware['dedicated-auth0'] = middleware['dedicated-auth0'].default || middleware['dedicated-auth0']

middleware['dedicated-sso'] = require('../src/middleware/dedicated-sso.ts')
middleware['dedicated-sso'] = middleware['dedicated-sso'].default || middleware['dedicated-sso']

middleware['maintenance'] = require('../src/middleware/maintenance.ts')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

export default middleware
