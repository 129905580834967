import { datadogRum } from '@datadog/browser-rum';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { PaymentConfig } from '@white-label-configuration/constants';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';

export function getAppPaymentProviderConfig(): PaymentConfig | null {
  if (isBookingPortal) {
    return null;
  }

  const paymentProviders = getAppVariable<Array<PaymentConfig>>('payments') ?? [];

  // filter for the active payment provider
  // DEVELOPER NOTE: this may return multiple payment providers due to the way the payment providers are stored in the partner config
  const activeProviders: Array<PaymentConfig> = paymentProviders.filter((paymentProvider: PaymentConfig) => paymentProvider.is_active);

  if (activeProviders.length === 0) {
    const error = new Error('No active payment providers found in the partner config');
    datadogRum.addError(error);
    throw error;
  }

  // - return the last one as it is the most recently added if there are multiple active payment providers or,
  // - return the only (first) active payment provider if there is only one active payment provider
  return activeProviders[activeProviders.length - 1];
}