import { defineStore } from 'pinia';

// Types
import type { UserData } from '@white-label-types/stores';

export interface PaymentFormState {
  formData: null | UserData,
  timestamp: null | string,
}

export type StoreFormDataPayload = {
  formData: UserData,
  timestamp?: string
}

/**
 * Payment form store definition
 *
 * @returns {StoreDefinition}
 */
export const usePaymentFormStore = defineStore({
  id: 'paymentForm',

  actions: {
    commitStoreFormData(payload: StoreFormDataPayload) {
      // @ts-expect-error - types are correct but package is out of date
      this.formData = { ...payload.formData };
      // @ts-expect-error - types are correct but package is out of date
      if (payload.timestamp) this.timestamp = payload.timestamp;
    },

    commitClearFormData() {
      // @ts-expect-error - types are correct but package is out of date
      this.formData = null;
      // @ts-expect-error - types are correct but package is out of date
      this.timestamp = null;
    },
  },

  getters: {
    // @ts-expect-error - types are correct but package is out of date
    readPaymentFormData: (state) => state.formData,
  },

  state() {
    return {
      formData: null,
      timestamp: null,
    }
  },

  // @ts-expect-error - Persist plugin is not typed
  persist: {
    storage: process.client ? sessionStorage : undefined,
  },
});
