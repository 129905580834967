
import { defineComponent, PropType } from 'vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Types
import type { CartItem, Item } from '@white-label-types/parking-checkout';
import type { POI } from '@white-label-types/parking-booking';
import { Partners } from '@white-label-types/partners';
export type Airport = Partners['poi'];

type SummaryElement = CartItem | Item;

export default defineComponent({
  name: 'SummaryAirport',
  props: {
    product: {
      type: Object as PropType<SummaryElement>,
    },
  },
  computed: {
    airport(): { code: string; name: string } {
      const airportCode = this.product?.search_criteria?.[this.product?.product_code]?.airport_code || this.product?.locationInfo?.code;
      const airport = getAppVariable('pois').find(
        (poi: POI) => poi.code === airportCode
      );
      let name = '';
      let code = '';
      if (airport) {
        if (airport.code === 'BFS') {
          name = 'Belfast International';
        } else if (airport.code === 'LCY') {
          name = 'London City';
        } else {
          name = airport.name
            .replace(/International|Airport|London/g, '')
            .trim();
        }
        code = airport.code;
      }
      return { code, name };
    },
  },
});
