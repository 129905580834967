import { datadogRum } from '@datadog/browser-rum';
import {
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDERS_IDS,
  PaymentConfig,
  PaymentProvider,
} from '@white-label-configuration/constants';
import { getAppPaymentProviderConfig } from '@white-label-helper/get-app-payment-provider-config';

/**
 * Get the active payment provider from the partner config
 *
 * DEVELOPER NOTE:
 *
 * This function will attempt to get the active payment provider from the partner channel config.
 *
 * If no active payment provider is found, an error will be thrown.
 * If a single payment provider is found then it will be returned.
 * if multiple payment providers are found, the last one will be returned.
 *
 * _The last one is chosen because it is presumably the latest one to of been created. If this is
 * not the case then the problem is with the partner config data and not this function._
 *
 * For BookingPortal partners, this function will return null.
 *
 * @returns {PaymentProvider} The active payment provider
 */
export function getAppPaymentProvider(): PaymentProvider | null {
  const paymentProviderConfig: PaymentConfig | null = getAppPaymentProviderConfig();

  // If Booking portal - don't throw an error
  if (!paymentProviderConfig) {
    return null;
  }

  const paymentProviderIdsEnvironmental = PAYMENT_PROVIDERS_IDS[process.env.NUXT_ENV_ENVIRONMENT ?? 'development'];

  // find the payment provider tuple in the supported payment providers
  const paymentProviderTupleOrUndefined = Object.entries(paymentProviderIdsEnvironmental).find(([key]) => key === `${paymentProviderConfig?.payment_provider_id}`);

  if (paymentProviderTupleOrUndefined === undefined) {
    const error = new Error(`Payment provider with id ${paymentProviderConfig?.payment_provider_id} not found among the supported payment providers`);
    datadogRum.addError(error);
    throw error
  }

  return PAYMENT_PROVIDERS[paymentProviderTupleOrUndefined[1]];
}
