
import { defineComponent, PropType } from 'vue';
import type { DropdownFlightLookupItems } from '@white-label-types/dropdown';
import TextField from '../text-field/text-field.vue';
import { IconMessage } from 'ui-shared-components';

export default defineComponent({
  name: 'FlightLookup',
  components: {
    TextField,
    IconButton: () => import('@white-label-icon/icon-button'),
    IconAskMark: () => import('@white-label-icon/icon-ask-mark'),
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconMessage,
  },

  props: {
    items: {
      type: Array as PropType<DropdownFlightLookupItems>,
      required: true,
    },
    value: {
      type: Object|null,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: '',
      filteredItems: [],
      dropdownOpened: false,
      selectedItem: null as DropdownFlightLookupItems | null,
      isTooltipVisible: false,
      isEditing: false,
    };
  },
  computed: {
    prependIcon() {
      return () => import('@white-label-icon/icon-search');
    },
  },
  watch: {
    searchQuery() {
      this.filterItems();
    },
    items: {
      deep: true,
      handler() {
        this.filterItems();
      }
    },
  },
  created() {
    this.filterItems();
  },
  methods: {
    filterItems() {
      const query = this.searchQuery.toLowerCase();
      this.filteredItems = this.items.filter(item => {
        const airlineName = item.airlineName?.toLowerCase().includes(query);
        const terminal = item.terminal ? item.terminal.toLowerCase().includes(query) : false;
        const flightNumber = item.flightNumber?.toLowerCase().includes(query);
        const city = item.city?.toLowerCase().includes(query);
        const airport = item.airport?.toLowerCase().includes(query);

        return airlineName || terminal || flightNumber || city || airport;
      });
    },
    openDropdown() {
      this.dropdownOpened = true;
    },
    closeDropdown(): void {
      this.dropdownOpened = false;
    },
    selectItem(item: DropdownFlightLookupItems) {
      this.isEditing = false;
      this.selectedItem = item;
      this.$emit('input', item);
      this.closeDropdown();
    },
    clearSelection() {
      this.searchQuery = '';
      this.selectedItem = null;
      this.$emit('input', null);
    },

    showTooltip(): void {
      this.isTooltipVisible = true;
    },

    hideTooltip(): void {
      this.isTooltipVisible = false;
    },
    editSelection() {
      this.isEditing = true;
      this.openDropdown();
    },
  },
});
