
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IconCheck: () => import('@white-label-icon/icon-check'),
  },

  props: {
    lookupDetails: {
      type: Array,
      default: () => []
    },
    flightNumber: {
      type: String,
      default: ''
    },
  }
});
