import type { Context } from '@white-label-types/middleware';
import { AuthObject } from '@white-label-types/auth';
import { handleDedicatedAuth0 } from '@white-label-helper/dedicated-flow';

interface ContextWithInstalledModules extends Context {
    $auth: AuthObject;
}

const dedicatedAuth0 = async (context: ContextWithInstalledModules): Promise<void> => {
    await handleDedicatedAuth0(context);
};

export default dedicatedAuth0;
