import { getAppVariable } from '@white-label-helper/get-app-variable';

interface WhitelabelHostDomains {
  account: string;
  checkout: string;
  ecommerce: string;
}

/**
 * Returns the cookie domain
 *
 * Developer Note:
 * This helper does not account for inconsistent domains. meaning using a.com, b.com and c.com will only return .com
 * as the cookie domain. This is because we cannot reliably remove top level domains from the provided hostnames due
 * not having a full list of top level domains. This is on the developer (or the person configuring the environment)
 * to ensure that domains are consistent and will work with this implementtion.
 *
 *
 * @returns string
 */
export const getCookieDomain = (): string => {
  const pageLinks = getAppVariable('page_links')
  const hostnames: WhitelabelHostDomains = {
    account: process.env.NUXT_ENV_ACCOUNT_APP_HOST_DOMAIN_OVERRIDE || pageLinks.whitelabel_host_domains.account,
    checkout: process.env.NUXT_ENV_CHECKOUT_APP_HOST_DOMAIN_OVERRIDE || pageLinks.whitelabel_host_domains.checkout,
    ecommerce: process.env.NUXT_ENV_ECOMMERCE_APP_HOST_DOMAIN_OVERRIDE || pageLinks.whitelabel_host_domains.ecommerce,
  }

  const ensureProtocol = (hostname: string): string => {
    if (!hostname.startsWith('http://') && !hostname.startsWith('https://')) return `https://${hostname}`;
    else return hostname;
  };

  const accountNormalisedURL = new URL(ensureProtocol(hostnames.account));
  const checkoutNormalisedURL = new URL(ensureProtocol(hostnames.checkout));
  const ecommerceNormalisedURL = new URL(ensureProtocol(hostnames.ecommerce));

  const reversedSplitHostnames = {
    account: accountNormalisedURL.hostname.split('.').reverse(),
    checkout: checkoutNormalisedURL.hostname.split('.').reverse(),
    ecommerce: ecommerceNormalisedURL.hostname.split('.').reverse(),
  }

  const matchedSegments: Array<string> = [];

  for (let index = 0; index < reversedSplitHostnames.account.length; index++) {
    const ecommerceSegment = reversedSplitHostnames.account[index];

    const matchesAccountSegment = ecommerceSegment === reversedSplitHostnames.account[index];
    const matchesCheckoutSegment = ecommerceSegment === reversedSplitHostnames.checkout[index];

    if (matchesAccountSegment && matchesCheckoutSegment) matchedSegments.push(ecommerceSegment);
    else break;
  }

  matchedSegments.reverse()

  // Note: Read comments in docblock above for more context
  // on this implementation
  const commonDomain = `${matchedSegments[0] === 'localhost' ? '' : '.'}${matchedSegments.join('.')}`;

  return commonDomain;
}

export default {
  getCookieDomain,
};
