
// Vue
import { defineComponent, PropType } from 'vue';
import type { CancellationProtection } from '@white-label-types/parking-checkout';
import { mapState } from 'pinia';
import { useCartCheckoutStore } from '@white-label-store/cart-checkout';

export default defineComponent({
  name: 'UpgradePolicies',

  components: {
    IconShield: () => import('@white-label-icon/icon-shield'),
  },

  props: {
    bundleProductsUpgradePolicies: {
      type: Array as PropType<CancellationProtection[]>,
      default: function () {
        return [];
      },
    },
    nonBundleProductsUpgradePolicies: {
      type: Array as PropType<CancellationProtection[]>,
      default: function () {
        return [];
      },
    },
    isBookingFeeCharged: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    ...mapState(useCartCheckoutStore, {
      readCartBundle: 'readCartBundle'
    }),
    bundleName(): string {
      return this.readCartBundle?.name || '';
    },
  },
});
