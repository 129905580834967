import { defineStore } from 'pinia';
import {
  fetchCartItems,
  AddToCartData,
  addToCart,
  deleteCartItem,
  fetchCart
} from '@white-label-helper/api-parking-cart';
import useDepositStore from '@white-label-store/deposits';

import type { SearchCriteria } from '@white-label-types/product-types';
import type {
  ProductOptions,
  GenerateProduct,
  ExtrasCancellationProtectionProduct,
  BookingFee,
  Totals,
  SearchProducts
} from '@white-label-types/parking-booking';
import type { Bundle } from '@white-label-types/parking-checkout';
import { PRODUCT_TYPES } from '@white-label-configuration/constants';

interface CartProduct extends GenerateProduct {
  seach_criteria: SearchCriteria;
}

interface CartBundle extends Bundle {
  hasParking: boolean;
  hasLounge: boolean;
  hasInboundFasttrack: boolean;
  hasOutboundFasttrack: boolean;
}

export type ProductsType = CartProduct | ExtrasCancellationProtectionProduct;

/**
 * Represents the cart store state.
 */
export type CartStoreState = {
  /**
   * The current cart token.
   */
  token: string | null;

  /**
   * The items in the cart.
   */
  items: (ProductsType)[];

  /**
   * The bundle in the cart.
   */
  bundle: CartBundle | null;

  /**
   * The cancellation protection product in the cart.
   */
  cancellationProtection: null | ProductOptions['details']['cancellation_protection'] | ExtrasCancellationProtectionProduct['cancellation_protection']

  addedExtras: string[];

  /**
   * Totals object for the current cart.
   * Can be null if the cart is empty.
   */
  totals: null | Totals;

  /**
   * Booking fee for the current cart.
   */
  bookingFee: null | BookingFee;

  itemIndex: null | number;
  itemListName: string;
  lastKnownFilter: string;

  /**
   * True if the cart is a multi-basket cart.
   */
  isMultiBasket: boolean;

  /**
   * The latest item added to the cart.
   */
  latestItem: null | ProductsType;

  /**
   * True if the cart has been updated.
   */
  cartUpdated: boolean;

  /**
   * True if there is an ongoing cart operation.
   */
  cartLoading: boolean;

  /**
   * The search product items that are in the cart.
   */
  searchProductsInCart: SearchProducts[];
}

/**
 * Cart Store definition
 *
 *  @returns {StoreDefinition}
 */
export const useCartStore = defineStore({
  id: 'cart',

  actions: {
    commitStoreItem(itemToStore: ProductsType) {
      // Prevent adding duplicates commitStoreItem array.
      const index = this.items.findIndex(item => item.id === itemToStore.id);

      if (index !== -1) {
        Object.assign(this.items[index], itemToStore);
      } else {
        this.items.push(itemToStore);
      }
      this.latestItem = itemToStore;
    },
    addToSearchProductsInCart(searchProduct: SearchProducts) {
      if (!searchProduct) return;
      const index = this.searchProductsInCart
        .findIndex(product => product?.cart_item_id === searchProduct.cart_item_id);

      if (index !== -1) {
        Object.assign(this.searchProductsInCart[index], searchProduct);
      } else {
        this.searchProductsInCart.push(searchProduct);
      }
    },
    commitStoreItemList(items: ProductsType[]) {
      this.cartUpdated = false;
      this.items = items;
    },
    updateSearchProductList() {
      if (!this.token) this.searchProductsInCart = [];
      this.searchProductsInCart = this.searchProductsInCart
        .filter((product) => product && this.cartItemIds.includes(product.cart_item_id));
    },
    commitStoreBundle(bundle: Bundle) {
      if (!bundle) {
        this.bundle = null;
        return;
      }
      this.bundle = {
        ...bundle,
        hasParking: bundle.items.some((item) => item.product_code === PRODUCT_TYPES.PARKING),
        hasLounges: bundle.items.some((item) => item.product_code === PRODUCT_TYPES.LOUNGES),
        hasInboundFasttrack: bundle.items.some((item) => item.product_code === PRODUCT_TYPES.FASTTRACK && item.inventory_item?.product_option?.code === 'flying_in'),
        hasOutboundFasttrack: bundle.items.some((item) => item.product_code === PRODUCT_TYPES.FASTTRACK && item.inventory_item?.product_option?.code === 'flying_out'),
      };
    },
    commitIsMultiBasket(payload: boolean) {
      this.isMultiBasket = payload;
    },
    commitCartUpdated(payload: boolean) {
      this.cartUpdated = payload;
    },
    commitStoreTotals(payload: Totals) {
      this.totals = payload;
    },
    commitStoreBookingFee(payload: BookingFee) {
      this.bookingFee = payload;
    },
    storeLatestItem(payload: ProductsType) {
      this.latestItem = payload;
    },
    commitStoreToken(payload: string) {
      this.token = payload;
    },
    commitClearCart(newToken: string | null = null) {
      this.items = [];
      this.token = newToken;
      this.cancellationProtection = null;
      this.addedExtras = [];
      this.totals = null;
    },
    commitStoreCancellationProtection(payload) {
      this.cancellationProtection = payload;
    },
    commitAddExtra(id: string) {
      this.addedExtras.push(id);
    },
    commitRemoveExtra(id: string) {
      this.addedExtras = this.addedExtras.filter((i) => i !== id);
    },
    commitStoreItemIndex(index: number) {
      this.itemIndex = index;
    },
    commitStoreItemListName(listName: string) {
      this.itemListName = listName;
    },
    commitLastKnownFilter(filter: string) {
      this.lastKnownFilter = filter;
    },
    removeCartItem(id: string) {
      this.items = this.items.filter((item) => item.id !== id);
    },
    commitCartLoading(isLoading: boolean) {
      this.cartLoading = isLoading;
    },
    async dispatchFetchCartItems() {
      try {
        if (this.token === null) {
          throw new Error('No token provided');
        }
        const cartData = await fetchCartItems(this.token);
        const deposit = useDepositStore();
        deposit.updateCartDepositTotal({
          ...cartData.payable
        });
        deposit.updateItemDeposit(cartData.items);
        this.commitStoreTotals(cartData.totals);
        this.commitStoreBookingFee(cartData.booking_fee);
        this.commitStoreItem(cartData.items[0]);
        this.updateSearchProductList();

        if (!cartData.items[0].bundle_id) {
          this.commitStoreCancellationProtection(cartData.items[0].cancellation_protection);
        }
        if (cartData.bundle) {
          this.commitStoreBundle({
            ...cartData.bundle,
            items: [...cartData.items.filter((item) => item.bundle_id === cartData.bundle.id)]
          });
        } else {
          this.commitStoreBundle(null);
        }
      } catch (e) {
        this.commitClearCart();
        throw e;
      }
    },
    async dispatchFetchCart() {
      if (this.token === null) {
        throw new Error('No token provided');
      }
      this.commitCartLoading(true);
      try {
        const { items, totals } = await fetchCart(this.token);
        this.commitStoreItemList(items);
        this.commitStoreTotals(totals);
        this.updateSearchProductList();
      } catch (e) {
        throw new Error(e instanceof Error ? e.message : String(e));
      } finally {
        this.commitCartLoading(false);
      }

    },
    async dispatchAddToCart(data: AddToCartData) {
      const deposit = useDepositStore();
      try {
        const cartData = await addToCart(data);
        const { items, totals, booking_fee } = cartData;
        deposit.updateCartDepositTotal({
          ...cartData.payable
        });
        deposit.updateItemDeposit(cartData.items);

        const item = items[items.length - 1];
        this.commitStoreItem(item);
        this.commitStoreTotals(totals);
        this.commitCartUpdated(true);

        if (data.searchProduct) {
          this.addToSearchProductsInCart(data.searchProduct);
        }

        const cancellationProtection = item?.inventory_option?.details?.cancellation_protection;
        const isCancellationProtectionAvailable = cancellationProtection?.is_available;

        if (isCancellationProtectionAvailable) {
          this.commitStoreBookingFee(booking_fee);
          this.commitStoreCancellationProtection(cancellationProtection);
        }

        return { items, totals, booking_fee };
      } catch (error) {
        console.error('Error adding to cart:', error);
        throw new Error(error instanceof Error ? error.message : String(error));
      }
    },
    async dispatchRemoveFromCart(itemId: string): Promise<void> {
      const deposit = useDepositStore();
      if (!this.token) {
        throw new Error('No token provided');
      }

      try {
        const cartData = await deleteCartItem(this.token, itemId)
        const { items, totals } = cartData;
        this.removeCartItem(itemId);
        this.updateSearchProductList();
        deposit.updateCartDepositTotal({
          ...cartData.payable
        });
        deposit.updateItemDeposit(cartData.items);

        if (items.length === 0) {
          this.commitClearCart();
        }

        this.commitStoreTotals(totals);
      } catch (error) {
        console.error('Error removing item from cart:', error);
        throw error;
      }
    },
    readOrCreateCartToken(uuid: string): string {
      let cartToken = this.readToken;
      if (!cartToken) {
        cartToken = uuid;
        this.commitStoreToken(cartToken);
      }
      return cartToken;
    }
  },

  getters: {
    readCartItems: (state) => state.items,
    cartItemIds: (state) => state.items.map((item) => item.id),
    readSearchProductsInCart: (state) => state.searchProductsInCart,
    readBundle: (state) => state.bundle,
    readToken: (state) => state.token,
    readTotals: (state) => state.totals,
    readBookingFee: (state) => state.bookingFee,
    readAddedExtras: (state) => state.addedExtras,
    readCancellationProtection: (state) => state.cancellationProtection,
    readItemIndex: (state) => state.itemIndex,
    readItemListName: (state) => state.itemListName,
    readLastKnownFilter: (state) => state.lastKnownFilter,
    readCartUpdated: (state) => state.cartUpdated,
    readLatestItem: (state) => state.latestItem,
    readCartLoading: (state) => state.cartLoading
  },
  state: (): CartStoreState => ({
    token: null,
    items: [],
    bundle: null,
    cancellationProtection: null,
    addedExtras: [],
    totals: null,
    bookingFee: null,
    itemIndex: null,
    itemListName: '',
    lastKnownFilter: '',
    isMultiBasket: false,
    latestItem: null,
    cartUpdated: false,
    cartLoading: false,
    searchProductsInCart: []
  }),
  persist: [
    {
      storage: process.client ? localStorage : undefined,
      paths: [
        'token',
        'items',
        'bundle',
        'cancellationProtection',
        'addedExtras',
        'totals',
        'bookingFee',
        'itemIndex',
        'itemListName',
        'isMultiBasket',
        'latestItem',
        'cartUpdated',
        'cartLoading',
        'searchProductsInCart'
      ]
    },
    {
      storage: process.client ? sessionStorage : undefined,
      paths: ['lastKnownFilter']
    }
  ]
});
