
import { defineComponent } from 'vue';
import { mapState } from 'pinia'

// Helpers
import { usePartnerConfigHelper } from '@white-label-helper/shared-helpers';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';

// Store
import { useManageBookingStore } from '@white-label-store/manage-booking';

// Components
import Button from '../button/button.vue';

type BookingStatus = typeof BOOKING_STATUSES[keyof typeof BOOKING_STATUSES];

export default defineComponent({
  name: 'ManageBookingCancelButtons',

  components: {
    ControlButton: Button,
  },

  props: {
    isLoading: {
      type: Boolean,
    },
    disableCancel: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState(useManageBookingStore,['readBookingStatus']),
    orderStatus(): BookingStatus {
      return this.readBookingStatus;
    },

    crossSellOrMultiBasketEnabled() {
      const { isCrossSellFlowEnabled, isMultiBasketEnabled } = usePartnerConfigHelper();
      return isCrossSellFlowEnabled || isMultiBasketEnabled;
    },

    isButtonDisabled(): boolean {
      return !this.crossSellOrMultiBasketEnabled && this.orderStatus !== BOOKING_STATUSES.CONFIRMED;
    },
  },

  methods: {
    cancelBookingHandler(): void {
      this.$emit('cancel-booking');
    },

    keepBookingHandler(): void {
      this.$emit('keep-booking');
    },
  },
});
