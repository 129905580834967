// Types
import type { Cart, ParsedDiscount } from '@white-label-types/parking-checkout';

export function parseDiscountCodeData(cart: Cart): ParsedDiscount | null  {
  const items = cart?.items;
  const discountItem = items.find((item) => item.discount !== null && Object.keys(item.discount).length);

  if (discountItem && discountItem.discount !== null) {
    const { code, amount, description } = discountItem.discount;
    const { subtotal, total } = cart.totals;

    return {
      code,
      amount,
      description,
      subtotal,
      total,
      orderTotals: cart.totals,
      items,
      payable: cart.payable,
      bundle: cart.bundle,
    };
  }

  return null;
}
