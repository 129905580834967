
import { defineComponent } from 'vue';
import ProductTags from '../product-tags/product-tags.vue';
import { APP_HERO_PRODUCTS } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BTicketHeader',
  components: { ProductTags },

  props: {
    product: {
      type: Object,
      required: true,
    },

    productName: {
      type: String,
      default: '',
    },

    airportName: {
      type: String,
      default: '',
    },

    orderId: {
      type: String,
      default: '',
    },
  },
  computed: {
    isFasttrackProduct() {
      return this.product.product_code === APP_HERO_PRODUCTS.FASTTRACK || this.product.productHeader?.product_code === APP_HERO_PRODUCTS.FASTTRACK;
    }
  }
});
