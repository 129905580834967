import { httpBooking } from '@white-label-configuration/http';
import { parseReceiptState } from '@white-label-helper/data-parsers-receipt'
import { datadogRum } from '@datadog/browser-rum';

// @ts-expect-error property
httpBooking.onRequest((config) => {
  const session_id = datadogRum?.getInternalContext()?.session_id || null;
  config.headers['X-Datadog-Session-ID'] = session_id;
});

// This request is used to get receipts in all flows:
// original receipt, manage booking receipt, cancellation receipt
export const getReceipt = (managementToken: string) => (
  httpBooking.get(
    '/booking/receipt/',
    { headers: { 'Management-Token': managementToken } },
  )
    .then((response) => parseReceiptState(response?.body?.data))
);

/**
 * Resend a confirmation email through the my account service.
 * Requires a valid auth token, order_id of the booking and
 * email to send receipt to.
 */
export const myAccountResendReceipt = (
  { token, order_id, email }:
    { token: string, order_id: number, email: string },
) => (
  httpBooking.post(
    'account/receipt/resend',
    {
      headers: { Authorization: token },
      body: { order_id, email },
    },
  )
    .then((response) => response.status)
);
