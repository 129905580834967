import { getAppVariable } from '@white-label-helper/get-app-variable';

/**
 * Returns the logout redirect URL
 *
 * @returns string
 */
export const getLogoutRedirectUrl = (): string => {
  let host = (
    process.env.NUXT_ENV_ACCOUNT_APP_HOST_DOMAIN_OVERRIDE ||
    getAppVariable('page_links.whitelabel_host_domains')?.account ||
    getAppVariable('page_links.domain')
  );

  const hostStartsWithProtocol = host.startsWith('http://') || host.startsWith('https://');

  const url = new URL(hostStartsWithProtocol ? host : `${window.location.protocol}//${host}`);
  host = url.toString();

  if (host.endsWith('/')) return `${host}login`;
  else return `${host}/login`;
}

export default {
  getLogoutRedirectUrl,
}
