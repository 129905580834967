
import { defineComponent } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import { getAppVariable } from '@white-label-helper/get-app-variable';

import type { CartItem, Receipt } from '@white-label-types/parking-checkout';
type SummaryElementItems = CartItem[] | Receipt['items'];
type SummaryElementItem = SummaryElementItems extends (infer U)[] ? U : never;

import { ProductSummaryCard, CavuButton, SummaryAirport } from 'ui-shared-components';
// Store
import { mapState, mapActions } from 'pinia';
import { useCartStore } from '@white-label-store/cart';
import { useMultiBasketStore } from '@white-label-store/multi-basket';
import { ROUTE_NAMES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'BasketMobile',
  components: {
    CavuButton,
    IconArrowDownSmall: () => import('@white-label-icon/icon-arrow-down-small'),
    IconBasket: () => import('@white-label-icon/icon-basket'),
    IconClose: () => import('@white-label-icon/icon-close'),
    IconCart: () => import('@white-label-icon/icon-cart'),
    ProductSummaryCard,
    SummaryAirport,
  },
  data() {
    return {
      isVisible: false,
      activeClass: '',
      isExpanded: false,
    };
  },
  computed: {
    ...mapState(useCartStore, {
      readTotals: 'readTotals',
      readCartItems: 'readCartItems'
    }),
    ...mapState(useMultiBasketStore, {
      readShowBasketNotification: 'readShowBasketNotification',
      readShowBasketSlideOver: 'readShowBasketSlideOver',
    }),

    iconArrowDetailClasses() {
      return { 'arrow-icon--rotate': this.isExpanded };
    },

    isOpen() {
      return this.readShowBasketSlideOver;
    },
    total(): string | Intl.NumberFormat {
      const total = Number(this.readTotals?.total);
      return formatPrice(total);
    },
    cartItems() {
      return this.readCartItems;
    },
    showBasket(): boolean {
      if (!this['isMultiBasket']) return false;
      const currentRouteName = this.$route.name;
      if (
        currentRouteName !== ROUTE_NAMES.home &&
        currentRouteName !== ROUTE_NAMES.search
      )
        return false;
      if (currentRouteName === ROUTE_NAMES.search) return true;
      return this.hasBasketItems;
    },
    hasBasketItems(): boolean {
      return this.cartItems.length > 0;
    },
    basketValue(): string {
      const totals = this.cartItems.reduce((total, item) => {
        const itemTotals =
          'original_amounts' in item
            ? item.original_amounts.totals
            : (item as SummaryElementItem).totals;
        return total + Number(itemTotals.total);
      }, 0);
      return this.hasBasketItems
        ? `${formatPrice(totals)}`
        : `${formatPrice(0)}`;
    },
    showBasketNotification() {
      return this.readShowBasketNotification;
    },
    isMultiBasket(): boolean {
      return !!getAppVariable('is_multi_basket');
    },
  },
  watch: {
    isOpen(val) {
      this.toggleActiveState(val);
    },
  },
  methods: {
    ...mapActions(useMultiBasketStore, ['commitShowBasketSlideOver']),
    toggleActiveState(isActive: boolean): void {
      if (isActive) {
        this.isVisible = true;
        setTimeout(() => {
          this.activeClass = 'basket-desktop__body--active';
        }, 200);
      } else {
        this.activeClass = '';
        setTimeout(() => {
          this.isVisible = false;
        }, 500);
      }
    },
    onContinueHandler() {
      this.$emit('continue');
    },
    onClose() {
      if (this.isOpen) {
        this.commitShowBasketSlideOver(false);
      }
    },
  },
});
