import type { SearchProducts, PurchaseProduct, GenerateProduct } from '@white-label-types/parking-booking';
import type { Cost, CartItem } from '@white-label-types/parking-checkout';
import type { GtmInjected as GTM } from '@white-label-types/nuxtjs-gtm';
import type { TranslateResult } from 'vue-i18n';

import { getAppVariable } from '@white-label-helper/get-app-variable';
import { formatPrice } from '@white-label-helper/helper-payment';

type TrackingProduct = {
  item_id: string,
  item_name: string,
  coupon: string,
  currency: string,
  discount: number,
  item_list_position: number,
  item_category: string,
  item_list_name: string,
  price: string | undefined,
  quantity: number,
  upgraded: boolean,
};

/** Gets the price without the currency symbol */
const getPrice = (price: number) => formatPrice(price).toString().match(/[\d.,]/g)?.join('');

const getPaymentType = (items: PurchaseProduct[]) => {
  const isPaypal = items.find(item => item.payment.payment_type?.toLowerCase().includes('paypal'));
  const isApple = items.find(item => item.payment.payment_type?.toLowerCase().includes('apple_pay'));
  const isGoogle = items.find(item => item.payment.payment_type?.toLowerCase().includes('google_pay'));

  if (isPaypal) {
    return 'PayPal';
  }

  if (isApple) {
    return 'Apple';
  }

  if (isGoogle) {
    return 'Google';
  }

  return 'Card';
};

export const convertCartItems = (items: GenerateProduct[]): TrackingProduct[] => items.map((item: GenerateProduct, index) => ({
  item_id: item?.inventory_id,
  item_name: item?.item_category === 'extras' ? 'cancellation protection' : item.inventory_item.name,
  coupon: item?.discount && item?.discount?.code ? item.discount.code : null,
  currency: getAppVariable('default_currency'),
  discount: item?.totals?.discount,
  item_brand: '',
  item_variant: '',
  item_list_id: index,
  item_category: item?.item_category,
  index: item?.meta?.filter?.index || index,
  item_list_name: item?.meta?.filter?.item_list_name,
  price: item?.item_category === 'extras' && item?.cancellation_protection
    ? getPrice(item?.cancellation_protection?.totals?.total)
    : getPrice(item?.totals?.total),
  quantity: 1,
  upgraded: item?.upgraded,
  bundle_id: item.bundle_id || '',
  bundle_name: item.bundle_name || '',
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function pushToGTM(gtm: GTM, gtmData: { [key: string]: any }) {
  if (gtm && gtmData) {
    gtm.push(gtmData);
  }
}

export function refreshDataLayer(gtm: GTM) {
  pushToGTM(gtm, { ecommerce: null });
}

export function trackError(gtm: GTM, errorMessage: string | TranslateResult) {
  pushToGTM(gtm, {
    event: 'error',
    message: errorMessage,
  });
}

export function pushIdentityCallback(gtm: GTM) {
  pushToGTM(gtm, {
    // @ts-expect-error - code provided to integrate mParticle
    identityCallback: function (result) {
      result.getUser().setUserAttributes(result.getPreviousUser().getAllUserAttributes());
      const previousUser = result.getPreviousUser();
      if (previousUser && Object.keys(previousUser.getUserIdentities().userIdentities).length === 0) {
        // @ts-expect-error - code provided to integrate mParticle
        const aliasRequest = mParticle.Identity.createAliasRequest(result.getPreviousUser(), result.getUser());
        // @ts-expect-error - code provided to integrate mParticle
        mParticle.Identity.aliasUsers(aliasRequest);
      }
    }
  });
}

export function trackUserIdentity(gtm: GTM, email: string, customerUUID?: string) {
  pushToGTM(gtm, {
    event: 'mp_idsync',
    userIdentities: {
      email,
      ...(customerUUID && {customer_id: customerUUID})
    }
  });
}
export function mapPurchaceItems(items: GenerateProduct[], bookingFee: number = 0) {
  const checkoutItems = items.map((item, index) => ({
    item_id: item.inventory_id,
    item_name: item.inventory_item.name,
    coupon: item.discount && item.discount.code ? item.discount.code : '',
    currency: getAppVariable('default_currency'),
    discount: item.totals.discount,
    affiliation: '',
    item_brand: '',
    item_list_position: index,
    index: index,
    item_category: item.isCancellationProtection ? 'extras' : item.product_code,
    item_list_name: 'search results',
    price: getPrice(item.totals.total),
    quantity: 1,
    bundle_id: item.bundle_id || '',
    bundle_name: item.bundle_name || '',
  }));

  const bookingFeeItem = {
    coupon: checkoutItems[0].coupon,
    currency: getAppVariable('default_currency'),
    discount: checkoutItems[0].discount,
    item_category: "channel fee",
    item_id: checkoutItems[0].item_id,
    item_list_name: "bookingFee",
    item_list_position: checkoutItems.length,
    item_name: "Booking Fee",
    price: bookingFee,
    quantity: 1,
  }

  return [...checkoutItems, bookingFeeItem];
}

export function trackBeginCheckout(gtm: GTM, items: GenerateProduct[], bookingFee: number = 0): void {
  const purchaseItems = mapPurchaceItems(items, bookingFee);
  pushToGTM(gtm, {
    event: 'begin_checkout',
    ecommerce: {
      items: purchaseItems,
    },
  });
}

export function trackSearchItemsImpression(
  gtm: GTM,
  searchResults: SearchProducts[],
  isTaxInclusive = false,
  isFeeInclusive = false,
  itemListName = 'search results',
) {
  const params = new URLSearchParams(window.location.search);
  const paramDiscountCode = params.get('discount') || '';

  const searchImpressions = searchResults.map((item, index) => ({
    item_id: item.productHeader.inventory_id,
    item_name: item.name,
    coupon: item?.discount?.code ? item.discount.code : paramDiscountCode,
    currency: getAppVariable('default_currency'),
    discount: item.productOptions[0].totals.discount || 0,
    index,
    item_category: item.productHeader?.product_code || 'parking',
    item_list_name: itemListName,
    price: getPrice(item.productOptions[0].totals.total),
    salesTaxIncluded: item.productOptions[0].totals.taxes !== 0 ? isTaxInclusive : false,
    feesIncluded: item.productOptions[0].totals.fees !== 0 ? isFeeInclusive : false,
    fees: item.productOptions[0].totals.fees,
    tax: item.productOptions[0].totals.taxes,
    quantity: 1,
  }));

  pushToGTM(gtm, {
    event: 'view_item_list',
    ecommerce: {
      items: searchImpressions,
    },
  });
}


function getTimeslotImpressionItem(item: object, index: number, itemListName: string): object {
  const params = new URLSearchParams(window.location.search);
  const paramDiscountCode = params.get('discount') || '';

  return {
    item_id: item.productHeader.inventory_id,
    item_name: item.name,
    coupon: item?.discount?.code ? item.discount.code : paramDiscountCode,
    currency: getAppVariable('default_currency'),
    discount: item.productOptions[0].totals.discount || 0,
    item_category: item.productHeader?.product_code || 'parking',
    item_list_name: itemListName,
    index,
    price: getPrice(item.productOptions[0].totals.total),
    quantity: item.quantity ?? 1,
    affiliation: null,
    item_brand: null,
    item_category2: null,
    item_list_id: null,
    item_variant: null,
    location_id: null
  };
}

export function trackSearchItemsImpressionForTimeslots(
  gtm: GTM,
  searchResults: SearchProducts[],
  itemListName = 'timeslot search results',
) {

  const searchImpressions = searchResults.map((item, index) => (
    getTimeslotImpressionItem(item, index, itemListName)
  ));

  pushToGTM(gtm, {
    event: 'view_item_list',
    ecommerce: {
      items: searchImpressions,
    },
  });
}

export function gaEventBookTimeslot(
  gtm: GTM,
  cartItems: SearchProducts[],
  currency: string,
  total: number
) {

  const timeslots = cartItems.map((item, index) => getTimeslotImpressionItem(item, index, 'timeslot selected'));

  pushToGTM(gtm, {
    event: 'view_cart',
    ecommerce: {
      currency: currency || null,
      value: total,
      items: timeslots,
    },
  });
}

/**
 *
 * @param gtm - The Data layer instance
 * @param items - The products
 * @param orderRef - The order ID
 * @param totals - Totals object of the order
 * @param bookingFee - Booking fee amount
 */
export function trackPurchaseImpression(
    gtm: GTM,
    items: PurchaseProduct[],
    orderRef: string,
    totals: Cost,
    bookingFee = 0,
    itemIndex = 0,
    itemListName = 'search results',
    event_name = 'purchase',
    bundleName = '',
): void {
  if (!Array.isArray(items)) {
    throw new ReferenceError('Items must be an array');
  }

  const products = items.map(item => ({
    item_id: item.orderId,
    item_name: item.parkingName,
    coupon: item?.discount?.code ? item.discount.code : '',
    affiliation: '',
    currency: item.payment.currency,
    discount: item.totals.discount || 0,
    item_list_position: itemIndex,
    item_category: item.product_code,
    item_list_name: itemListName,
    price: item.totals.total,
    tax: item.totals.taxes,
    fee: item.totals.fees,
    quantity: 1,
    bundle_id: item.bundle_id || '',
    bundle_name: bundleName || '',
  }));

  items.forEach((item) => {
    if (item.cancellation_protection) {
      const itemTaxesSummary = item.cancellation_protection?.taxes
          .reduce((acum: number, curValue : { amount: number }) => acum + curValue.amount, 0);

      products.push({
        item_id: item.cancellation_protection.id,
        item_name: 'cancellation protection',
        coupon: item?.discount?.code ? item.discount.code : '',
        currency: item.payment.currency,
        discount: item?.totals?.discount || 0,
        item_list_position: itemIndex,
        item_category: 'extras',
        item_list_name: itemListName,
        price: item.cancellation_protection.totals.total,
        tax: itemTaxesSummary,
        fee: 0,
        quantity: 1,
      });
    }
  });

  const hasCancellation = items.some(item => item.cancellation_protection?.id);
  const orderTaxesSummary = products.reduce((acum: number, curValue: { tax: number }) => acum + curValue.tax, 0);
  const orderFeesSummary = products.reduce((acum: number, curValue: { fee: number }) => acum + curValue.fee, 0);

  // Adjusted value reporting based on CAVU owned Channels
  const cavuOwnedValues = {
    value: totals.cavu_commission,
    basketValue: totals.total,
  }

  pushToGTM(gtm, {
    event: event_name,
    ecommerce: {
      bookingFee,
      transaction_id: orderRef,
      affiliation: '',
      ...(totals.cavu_commission ? cavuOwnedValues : {value: totals.total}),
      tax: orderTaxesSummary,
      fee: orderFeesSummary,
      currency: products[0].currency,
      coupon: '',
      addedExtra: hasCancellation ? 'cancellation protection' : null,
      paymentType: getPaymentType(items),
      items: products,
    },
  });
}

export function trackAddToCart(gtm: GTM, items: GenerateProduct[]): void {
  const cartItems = convertCartItems(items);
  pushToGTM(gtm, {
    event: 'add_to_cart',
    ecommerce: {
      items: cartItems,
    },
  });
}

export function trackRemoveFromCart(gtm: GTM, items: GenerateProduct[]): void {
  const cartItems = convertCartItems(items);

  pushToGTM(gtm, {
    event: 'remove_from_cart',
    ecommerce: {
      items: cartItems,
    },
  });
}

export function trackViewItem(gtm: GTM, item: SearchProducts, index: number, itemListName: string): void {
  const params = new URLSearchParams(window.location.search);
  const paramDiscountCode = params.get('discount') || '';

  const viewItem = {
    item_id: item.productHeader.inventory_id,
    item_name: item.name,
    coupon: paramDiscountCode || '',
    currency: getAppVariable('default_currency'),
    discount: item.productOptions[0].totals.discount || 0,
    item_list_position: index,
    item_category: 'parking',
    item_list_name: itemListName,
    price: getPrice(item.productOptions[0].totals.total),
    quantity: 1,
  };

  pushToGTM(gtm, {
    event: 'view_item',
    ecommerce: {
      items: [viewItem],
    },
  });
}

function getDiscount(item: CartItem | SearchProducts | PurchaseProduct): number {
  if(Object.values(item).includes("totals")){
    return item.totals.discount;
  }
  if (Object.values(item).includes("productOptions")) {
    return item.productOptions[0].totals.discount
  }
  return 0;
}

function getItemListName(item): string {
  const queryParams = new URLSearchParams(window.location.search);
  const parmsItemListName = queryParams.get('baggage_type');

  if(item?.search_criteria?.lounges?.baggage_type){
   return item.search_criteria.lounges.baggage_type === 'none' ? 'All' : item.search_criteria.lounges.baggage_type ;
  }

  if(item?.item_list_name) {
    return item.item_list_name;
  }

  if(parmsItemListName) {
    return parmsItemListName;
  }

  return 'search results';
}

/**
 *
 * @param gtm - The Data layer instance
 * @param event - event name
 * @param items - The products
 * @param refresh - Clear the previous ecommerce object.
 * @param params - extend a sending ecommerce object
 */
export function gaEventLounges(
  gtm: GTM,
  { event, items = [], params = {} }:
  {
    event: string,
    items: ((CartItem | SearchProducts | PurchaseProduct) & { index?: number })[],
    refresh?: boolean,
    params?: object,
  },
): void {
  const queryParams = new URLSearchParams(window.location.search);
  const paramDiscountCode = queryParams.get('discount') || '';

  pushToGTM(gtm, {
    event,
    ecommerce: {
      items: items.map((item, index: number) => {
        const defaultParams = {
          item_id: '',
          item_name: '',
          affiliation: '',
          coupon: paramDiscountCode,
          currency: getAppVariable('default_currency'),
          discount: 0,
          index: item?.index || index,
          item_brand: '',
          item_list_position: item?.index || index,
          item_category: 'lounges',
          item_list_name: getItemListName(item),
          price: 0,
          quantity: 1,
        };
        if ('inventory_id' in item) {
          Object.assign(defaultParams, {
            item_id: item.inventory_id,
            item_name: item.inventory_item?.name,
            price: getPrice(item?.totals?.total),
            quantity: 1,
          });
        } else if ('productHeader' in item) {
          Object.assign(defaultParams, {
            item_id: item?.productHeader?.inventory_id,
            item_name: item.name,
            price: getPrice(item?.productOptions?.[0]?.totals?.total),
            quantity: 1,
            product_code: item?.productHeader?.product_code
          });
        } else {
          Object.assign(defaultParams, item);
        }

        Object.keys(item).forEach((key) => {
          if (Object.hasOwnProperty.call(defaultParams, key)) {
            Object.assign(defaultParams, { [key]: item[key] });
          }
        });

        if(item?.discount && typeof item.discount === 'object' && item?.discount?.code) {
            defaultParams.coupon = item.discount.code
            defaultParams.discount = item.discount.amount
        } else {
          defaultParams.discount = getDiscount(item);
        }

        return { ...defaultParams };
      }),
      ...params,
    },
  });
}

export function trackPurchaseImpressionLounges(gtm: GTM, items: PurchaseProduct[], orderRef: string, event = 'add_payment_info'): void {
  let taxesTotal = 0;
  let price = 0;
  items.forEach((item) => {
    const taxes = item?.taxes || [];
    price += item.totals.total;
    taxes.forEach(({ amount }: { amount: number }) => { taxesTotal += amount; });
  });
  gaEventLounges(gtm, {
    event,
    items,
    params: {
      paymentType: getPaymentType(items),
      currency: items[0]?.payment?.currency,
      coupon: '',
      value: price,
      tax: taxesTotal,
      transaction_id: orderRef,
    },
  });
}
