
// Vue
import { defineComponent, PropType } from 'vue';

// Types
import type { Bundle } from '@white-label-types/parking-checkout';

// Components
import BundleCardHeading from './bundle-card-sub-components/bundle-card-heading.vue'
import BundleCardItem from './bundle-card-sub-components/bundle-card-item.vue'
import BundleCardCancellationProtection from './bundle-card-sub-components/bundle-card-cancellation-protection.vue'

export default defineComponent({
  name: 'ProductSummaryBundleCard',

  components: {
    BundleCardHeading,
    BundleCardItem,
    BundleCardCancellationProtection,
  },

  props: {
    bundle: {
      type: Object as PropType<Bundle>,
      required: true,
    },

    isCancelling: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasCancellationProtection(): boolean {
      return this.bundle.items.some((bundleItem) => bundleItem.is_protected
        || bundleItem.cancellation_policies?.is_protected)
        && this.bundle.is_cancellation_protection_auto_add;
    },

    isCancelled(): boolean {
      return !!(this.bundle.items.find((item) => {
        return item.status === 'cancelled';
      }));
    },

    cancellationStatus(): string | null {
      if (this.isCancelled) {
        return this.$t('UI.bookingStatuses.cancelled');
      }
      if (this.isCancelling) {
        return this.$t('UI.bookingStatuses.toBeCancelled');
      }
      return null;
    }
  },
});
