import { getAppPaymentProviderConfig } from '@white-label-helper/get-app-payment-provider-config';
import { PaymentConfig } from '@white-label-configuration/constants';

/**
 * Get the active payment provider merchant id from the partner config
 *
 * @returns {string} The active payment provider merchant id
 */
export function getAppPaymentMerchantId(): string {
  const paymentProviderConfig: PaymentConfig | null = getAppPaymentProviderConfig();

  return paymentProviderConfig?.merchant_id || '';
}
