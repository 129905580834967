import type { BookingFee } from '@white-label-types/parking-booking';
import { Bundle } from '@white-label-types/parking-checkout';

type CustomAxiosResponse<ITEMS, TOTALS, PAYABLE> = {
  body: {
    data: {
      items: ITEMS[];
      totals: TOTALS;
      payable: PAYABLE;
      booking_fee?: BookingFee;
      bundle: Bundle | null;
    }
  },
}

export function parseCartResponse<ITEMS, TOTALS, PAYABLE = unknown>(res: CustomAxiosResponse<ITEMS, TOTALS, PAYABLE>):
  { item: ITEMS, items: ITEMS[], totals: TOTALS, payable: PAYABLE, booking_fee?: BookingFee, bundle: Bundle | null } {
  const { items, totals, payable, booking_fee, bundle } = res.body.data;
  return {
    item: items[0],
    items,
    totals,
    payable,
    booking_fee,
    bundle,
  };
}
