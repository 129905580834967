import { cloneDeep, get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAppVariable<T = any>(key: string, defaultValue?: T): T {

  let partnerConfig: null | string | undefined;

  if (process.client && process.env.NUXT_ENV_IS_ECS) {
    const $scriptElement = document.querySelector('script[vmid="partner-channel-config"');

    if ($scriptElement) {
      partnerConfig = $scriptElement.textContent
    }
  }

  if (process.env['NUXT_ENV_PARTNER_CONFIG_JSON'] === undefined) {
    throw new ReferenceError('The "NUXT_ENV_PARTNER_CONFIG_JSON" environment variable must be defined')
  }

  if (process.env['NUXT_ENV_PARTNER_CONFIG_JSON'] === '') {
    throw new ReferenceError('The "NUXT_ENV_PARTNER_CONFIG_JSON" environment variable must be a raw JSON string')
  }

  if (partnerConfig === undefined || partnerConfig === null) {
    partnerConfig = process.env.NUXT_ENV_PARTNER_CONFIG_JSON;
  }

  let parsedPartnerConfig;

  try {
    parsedPartnerConfig = JSON.parse(partnerConfig);
  }

  catch (error) {
    throw new Error('The "NUXT_ENV_PARTNER_CONFIG_JSON" environment variable is set but not parsable as JSON')
  }

  const variable = cloneDeep(get(parsedPartnerConfig, key));

  if (variable === undefined) {
    // WL-4568 Temporary solution
    console.warn(`Missing '${key}' in partner config object`);
    // @ts-expect-error - default value could be undefined and the return type does not handle this.
    return defaultValue;
  }

  return variable;
}
