import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { PRODUCT_TYPES, ProductType } from '@white-label-configuration/constants';

/**
 * Exposes helper properties to describe the partner configuration.
 */
export function usePartnerConfigHelper () {
  const heroProductType = getAppHeroProduct(PRODUCT_TYPES.PARKING);
  const productTypesAvailable = getAppVariable<ProductType[]>('product_types_available', []);
  const isMultiBasketEnabled = getAppVariable<boolean>('is_multi_basket', false);

  const isCrossSellFlowEnabled = (() => {
    return (
      heroProductType === PRODUCT_TYPES.PARKING &&
      (productTypesAvailable.includes(PRODUCT_TYPES.LOUNGES) ||
      productTypesAvailable.includes(PRODUCT_TYPES.FASTTRACK))
    );
  })();

  const isExtrasProductFlowEnabled = (() => {
    return (
      !isCrossSellFlowEnabled &&
      productTypesAvailable.includes(PRODUCT_TYPES.EXTRAS)
    );
  })();

  const isStandalonePartner = (() => {
    return (
      productTypesAvailable.length === 1 &&
      productTypesAvailable[0] === heroProductType
    );
  })();

  return {
    isCrossSellFlowEnabled,
    isExtrasProductFlowEnabled,
    isMultiBasketEnabled,
    isStandalonePartner,
  }
}
