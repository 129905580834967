// Packages
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { CancelledEvent } from '@white-label-types/pusher';
import type { default as Pusher } from 'pusher-js';

import { cancellationCancelBooking, cancellationCancelBundle } from '@white-label-helper/api-manage-booking';
import { pusherErrorTimeout } from '@white-label-configuration/constants';
import getPusherChannel from '@white-label-helper/pusher-channel';

let channel: Pusher;

export function cancelBookingHelper() {
  let channelId: string;

  function destroyChannelListener() {
    if (channel === undefined) {
      channel = getPusherChannel();
    }

    channel.unsubscribe(channelId);
  }

  function cancelBooking(manageBookingToken: string, orderReference: string) {
    return new Promise<CancelledEvent['data']>((resolve, reject) => {
      const channelName = `order.${orderReference}`;

      const body = {
        'order_item_reference': orderReference,
        'reason': 'test reason',
        'meta': {
          'sync': 1,
          'forward': {},
        },
      };

      if (channel === undefined) {
        channel = getPusherChannel();
      }

      cancellationCancelBooking(manageBookingToken, body).catch((error: unknown) => {
        reject(new Error(error));
      });

      const localPusher = channel.subscribe(channelName);

      localPusher.bind('OrderItemCancelledEvent', (data: CancelledEvent['data']): void => {
        resolve(data);
      });
      localPusher.bind('error', (error: unknown) => {
        reject(new Error(error));
      });

      // Default timeout to reject request if it's frozen
      setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    }).catch((e: Error) => {
      datadogRum.addError(`pusher-cancel-booking: ${e}`);
      throw e;
    });
  }

  function cancelBundle(manageBookingToken: string, bundleId: string, orderReference: string, refundAmount: string) {
    return new Promise<CancelledEvent['data']>((resolve, reject) => {
      const channelName = `bundle.${orderReference}`;

      const body = {
        'bundle_id': bundleId,
        'refund_amount': Number(refundAmount),
        'meta': {
          'sync': 1,
          'forward': {},
        },
      };

      if (channel === undefined) {
        channel = getPusherChannel();
      }

      cancellationCancelBundle(manageBookingToken, body).catch((error: unknown) => {
        reject(error);
      });

      const localPusher = channel.subscribe(channelName);

      localPusher.bind('BundleCancelledEvent', (data: CancelledEvent['data']): void => {
        resolve(data);
      });
      localPusher.bind('error', (error: unknown) => {
        reject(error);
      });

      // Default timeout to reject request if it's frozen
      setTimeout(() => {
        const oneSecond = 1000;
        reject(new Error(`Timed out after ${pusherErrorTimeout / oneSecond} seconds`));
      }, pusherErrorTimeout);
    }).catch((e: Error) => {
      datadogRum.addError(`pusher-cancel-booking: ${e}`);
      throw e;
    });
  }

  return {
    cancelBooking,
    cancelBundle,
    destroyChannelListener,
  };
}
