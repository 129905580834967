import { getAppVariable } from '@white-label-helper/get-app-variable';

/**
 * Using the Intl.NumberFormat() constructor to converts a number into a currency string based on the partner language setting.
 * @param price - The price to format
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
const supportsNarrowSymbol = (() => {
  try {
    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    }).format(1) === '$1.00';
  } catch {
    return false;
  }
})();

export const formatPrice = (price: number): string => {
  if (Number.isNaN(price)) return '';

  const defaultCurrency = getAppVariable('default_currency');
  const defaultLanguage = getAppVariable('default_language') || [];
  const isEuro = defaultCurrency === 'EUR';

  let formattedPrice = new Intl.NumberFormat(defaultLanguage, {
    style: 'currency',
    currency: defaultCurrency,
    currencyDisplay: supportsNarrowSymbol ? 'narrowSymbol' : 'symbol',
    maximumFractionDigits: 2,
    useGrouping: !isEuro,
  }).format(price);

  if (isEuro) {
    formattedPrice = formattedPrice.replace(/\s?€/g, '')
      .replace('.', ',') + ' €';
  }

  return formattedPrice;
};

/**
 * Returns an Intl.NumberFormat instance configured with the default currency.
 * This formatter can be used for parsing price components.
 * @returns An instance of Intl.NumberFormat.
 */
export const formatCurrency = (): Intl.NumberFormat => {
  const defaultCurrency = getAppVariable('default_currency');
  const isEuro = defaultCurrency === 'EUR';

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: defaultCurrency,
    currencyDisplay: supportsNarrowSymbol ? 'narrowSymbol' : 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: !isEuro,
  });
};

export const formatCurrencyParts = (price: number): Intl.NumberFormatPart[] => {
  const formatter = formatCurrency();
  let parts = formatter.formatToParts(price);

  if (formatter.resolvedOptions().currency === 'EUR') {
    parts = parts.map(part =>
      part.type === 'decimal' ? { ...part, value: ',' } : part
    );
  }
  return parts;
};
