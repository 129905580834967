
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type { CartItem, CancellationProtection } from '@white-label-types/parking-checkout';
import type { CancellationPolicy, AmendmentPolicy, StandardPolicy } from '@white-label-types/parking-booking';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';

// Stores
import { useCartCheckoutStore } from '@white-label-store/cart-checkout';

// Packages
import { mapState } from 'pinia';

import RadioButton from '../radio-button/radio-button.vue';
import StandardPolicies from './sub-components/standard-policies.vue';
import UpgradePolicies from './sub-components/upgrade-policies.vue';

export default defineComponent({
  name: 'CancellationAndAmendmentPolicy',

  components: {
    RadioButton,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconShield: () => import('@white-label-icon/icon-shield'),
    StandardPolicies,
    UpgradePolicies,
  },

  props: {
    cancellationPolicies: {
      type: Array as PropType<CancellationPolicy[] | CartItem['inventory_option']['cancellation_policies'][]>,
      default: () => [],
    },
    amendmentPolicies: {
      type: Array as PropType<AmendmentPolicy[] | CartItem['inventory_option']['amendment_policies'][]>,
      default: () => [],
    },
    isProtected: {
      type: Boolean,
      required: true,
    },
    cancellationProtection: {
      type: Array as PropType<CancellationProtection[]>,
      required: true,
    },
    isBookingFeeCharged: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      standardPolicyExpanded: false,
      upgradedProtectionExpanded: true,
      selectedOption: 'standardOption',
    };
  },

  computed: {
    ...mapState(useCartCheckoutStore, {
      readCartBundle: 'readCartBundle'
    }),
    upgradedProtectionLabel() {
      return `
        ${this.$t('cancellationAndAmendment.upgradedPolicy')}
        ${formatPrice(this.cancellationProtectionTotal)}
      `;
    },
    isAdditionalCancellationProtectionAvailable(): boolean {
      // TO DO in ECOM-3090 -  need to handle the case where the bundle has cancellation protection auto-added,
      // but cancellation protection is also available on non-bundle items
      return this.cancellationProtection.some(item => item.is_available) && !this.isBundleCancellationProtectionAutoAdded;
    },
    cancellationProtectionTotal(): number {
      return this.cancellationProtection.reduce((acc, item) => acc + (item.totals?.total ?? 0), 0);
    },
    /**
     * Returns the standard cancellation and amendment policies of all products in cart.
     * @returns an array of StandardPolicy objects.
     */
    allProductsStandardPolicies(): StandardPolicy[] {
      return this.cancellationPolicies.map((cancellationPolicyItem: CancellationPolicy) => {
        const amendmentPolicyItem = this.amendmentPolicies.find((item: AmendmentPolicy) => item.product_id === cancellationPolicyItem.product_id);
        return {
          ...cancellationPolicyItem,
          amendment_policy: amendmentPolicyItem ? amendmentPolicyItem.amendment_policy : '',
        } as StandardPolicy;
      });
    },
    /**
     * Returns the standard cancellation and amendment policies of all cart items that are part of a bundle.
     * If the bundle has cancellation protection auto-added, the standard cancellation policy text of each eligible product is updated
     * with the upgraded policy text, and 'is_cancellation_protection_auto_added' is set to true.
     * @returns an array of StandardPolicy objects.
     */
    bundleProductsStandardPolicies(): StandardPolicy[] {
      let bundleProductsStandardPolicies = this.allProductsStandardPolicies.filter((item: StandardPolicy) => item.is_bundle_product);

      if (this.isBundleCancellationProtectionAutoAdded) {
        bundleProductsStandardPolicies = bundleProductsStandardPolicies.map((item) => {
          const cancellationProtectionItem = this.cancellationProtection.find(protectionItem => protectionItem.product_id === item.product_id);
          if (cancellationProtectionItem && cancellationProtectionItem.is_available) {
            return {
              ...item,
              cancellation_policy: cancellationProtectionItem.cancellation_policies[0].message,
              is_cancellation_protection_auto_added: true,
            };
          }
          return item;
        });
      }

      return bundleProductsStandardPolicies;
    },
    /**
     * Returns the standard cancellation and amendment policies of all cart items that are not part of a bundle.
     * @returns an array of StandardPolicy objects.
     */
    nonBundleProductsStandardPolicies(): StandardPolicy[] {
      return this.allProductsStandardPolicies.filter((item: StandardPolicy) => !item.is_bundle_product );
    },
    /**
     * Returns the cancellation protection policy details of cart items that
     * do have upgraded cancellation protection available, and are part of a bundle.
     * @returns an array of CancellationProtection objects or an empty array.
     */
    bundleProductsUpgradePolicies(): CancellationProtection[] | [] {
      return this.cancellationProtection.filter((item: CancellationProtection) => item.is_bundle_product && item.is_available);
    },
    /**
     * Returns the cancellation protection policy details of cart items that
     * do have upgraded cancellation protection available, and are not part of a bundle.
     * @returns an array of CancellationProtection objects or an empty array.
     */
    nonBundleProductsUpgradePolicies(): CancellationProtection[] | [] {
      return this.cancellationProtection.filter((item: CancellationProtection) => !item.is_bundle_product && item.is_available);
    },
    /**
     * Returns a boolean indicating whether a bundle in the cart has cancellation protection auto-added. Returns false if no bundle in cart.
     * @returns a boolean.
     */
     isBundleCancellationProtectionAutoAdded(): boolean {
      return this.readCartBundle?.is_cancellation_protection_auto_add || false;
    },
    /**
     * TO DO - Remove 'cancellationPolicyTitle' and 'amendmentPolicyTitle' when 'ECOM-2801_BUNDLES-PRE-PURCHASE' feature flag is no longer needed
     * These have been moved to another component
     */
    cancellationPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.cancellationPolicy').toLowerCase();
    },
    amendmentPolicyTitle(): string {
      return this.$t('cancellationAndAmendment.amendmentPolicy').toLowerCase();
    },
  },

  mounted() {
    if (this.isProtected) {
      this.selectedOption = 'upgradedOption';
    }
  },

  methods: {
    onRadioSelect(value: string): void {
      this.selectedOption = value;

      if (value === 'standardOption') {
        this.$emit('remove-cancellation-protection');
      } else {
        this.$emit('add-cancellation-protection');
      }
    },
  },
});
